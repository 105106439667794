<template>
    <div>
        <div class="parent-tool-div">
             <div>
               <p class="text-center pt-4">
                <img src="../../assets/images/logo2.png" alt="" height="68" >
                 </p>
                <h4 class="pt-4 pb-2  text-center">{{tran.agro_insurer}}</h4>
                <p class="text-center" style="font-size:18px"> We have carefully profiled the popular agro tech platforms and their insurance portfolio </p>
        </div>

        <div style="display:flex; margin: 0 auto; justify-content:center">
            <transition name="custom-classes-transition" enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft" :duration="{ enter: 8000, leave: 200 }">
                <div class="search-div mt-4" style="z-index:21" v-if="atpDiv">

                    <select  v-select v-model="atpSelected" @change="showAtpDetail(atpSelected)">
                    <option v-for="bank in atpObject" :value="bank.id" :key="bank.id">
                                                {{bank.name}}</option>
                                           </select>

                    <p class="text-center mt-4 mb-4"><b>{{tran.some_popular}}</b></p>

                    <div class="row">
                        <div class="col-md-6 col-6 atp-img" style="padding-left:0px">
                            <div class="atp-preview" @click="showAtpDetail(1)">
                                <p>Farmforte</p>

                            </div>
                        </div>

                        <div class="col-md-6 col-6">
                            <div class="atp-preview" @click="showAtpDetail(2)">
                                <p>Payfarmer</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-6" style="padding-left:0px">
                            <div class="atp-preview" @click="showAtpDetail(3)">
                                <p>Thrive Agric</p>
                            </div>
                        </div>

                        <div class="col-md-6 col-6">
                            <div class="atp-preview" @click="showAtpDetail(4)">
                                <p>Farmkart</p>
                            </div>
                        </div>

                    </div>

                    <div class="requid-sign-up text-center">
                        <div>
                            <p class="text-left">ReQuid allows you to invest in verified agro investment products, with an option to liquidate (sell) when you wish.</p>

                        </div>
                        <div>

                            <center>

                                <button class="mt-2" @click="gotoSignup">
                                                                       {{tran.signup}}
                                                                    </button>
                            </center>
                        </div>

                        <div style="position:relative">
                            <img src="../../assets/images/99070.svg" alt="" height="58" style="opacity:0.01; position: absolute; opacity: 0.1; right: 152px;top: 6px;">
                            <img src="../../assets/images/grow.svg" alt="" height="68" style="opacity:0.01; position: absolute; opacity: 0.1; right: 209px; top: 6px;">
                                        </div>

                        </div>

                    </div>
            </transition>

            <transition name="custom-classes-transition" enter-active-class="animated fadeIn" leave-active-class="animated fadeOutRight" :duration="{ enter: 300, leave: 400 }">

                <div class="search-div mt-4" style="z-index:21;" v-if="atpVerifiedDiv">

                    <div class="mb-4">
                        <div style="height:50px; display:flex; align-items:center;">
                            <div style="flex-basis:10%">
                                <img src="../../assets/images/chev.png"  class="mr-4" alt="" height="18" @click="showAtpDiv">
                            </div>
                                <div style="flex-basis:80%; display:flex; justify-content:center">
                                    <h6>{{atpName}}</h6>
                                </div>
                            </div>

                            <!-- <p class="ml-4 mb-4 text-center">{{atpName}}</p> -->

                        </div>
                        <p>{{tran.change_prod}}</p>
                        <select @change="getProductFromAtpProducts($event, returnAtpProducts)" v-select >
                    <option v-for="prod in returnAtpProducts" :value="prod.id" :key="prod.id" >
                                                {{prod.name}}</option>
                                           </select>

                        <div v-if="defaultProductSelected == false">
                            <div class="prod-div" style="display:flex; flex-grow:1">
                                <div style="flex-basis:10%">
                                    <img src="../../assets/images/name6.svg" alt="" height="38"  style="opacity:0.1">
                                </div>

                                    <div class="pl-2">
                                        <p> <b>{{tran.prod_name}}</b></p>
                                        <p>{{productSelectedByUser.name}}</p>

                                    </div>

                                </div>
                                <div class="prod-div" style="display:flex; flex-grow:1">
                                    <div style="flex-basis:10%">

                                        <img src="../../assets/images/name2.svg" alt="" height="38"  style="opacity:0.1">
                                        </div>

                                        <div class="pl-2">
                                            <p><b>{{tran.insurer}} </b></p>
                                            <p>{{productSelectedByUser.insurer}}</p>

                                        </div>

                                    </div>

                                    <div class="prod-div" style="display:flex; flex-grow:1">
                                        <div style="flex-basis:10%">

                                            <img src="../../assets/images/name3.svg" alt="" height="38"  style="opacity:0.1">
                                        </div>

                                            <div class="pl-2">
                                                <p><b>{{tran.status}}</b></p>
                                                <p>{{productSelectedByUser.status}}</p>

                                            </div>

                                        </div>

                                        <div class="prod-div" style="display:flex; flex-grow:1">
                                            <div style="flex-basis:10%">

                                                <img src="../../assets/images/name4.svg" alt="" height="38"  style="opacity:0.1">
                                        </div>

                                                <div class="pl-2" style="flex-basis:80%">
                                                    <p><b>{{tran.policy}}</b></p>

                                                    <p> {{productSelectedByUser.policy}} </p>

                                                </div>

                                            </div>

                                        </div>

                                        <div v-else>

                                            <div class="prod-div" style="display:flex; flex-grow:1">
                                                <div style="flex-basis:10%">

                                                    <img src="../../assets/images/name6.svg" alt="" height="38"  style="opacity:0.1">
                                        </div>

                                                    <div class="pl-2">
                                                        <p><b>{{tran.product_name}} </b></p>
                                                        <p>{{defaultProductSelected.name}}</p>

                                                    </div>

                                                </div>
                                                <div class="prod-div" style="display:flex; flex-grow:1">
                                                    <div style="flex-basis:10%">

                                                        <img src="../../assets/images/name2.svg" alt="" height="38"  style="opacity:0.1">
                                        </div>

                                                        <div class="pl-2">
                                                            <p><b>Insurer : </b></p>
                                                            <p>{{defaultProductSelected.insurer}}</p>

                                                        </div>

                                                    </div>

                                                    <div class="prod-div" style="display:flex; flex-grow:1">
                                                        <div style="flex-basis:10%">

                                                            <img src="../../assets/images/name3.svg" alt="" height="38"  style="opacity:0.1">
                                                     </div>

                                                            <div class="pl-2">
                                                                <p><b>Status : </b></p>
                                                                <p>{{defaultProductSelected.status}}</p>

                                                            </div>

                                                        </div>

                                                        <div class="prod-div" style="display:flex; flex-grow:1">
                                                            <div style="flex-basis:10%">
                                                                <img src="../../assets/images/name4.svg" alt="" height="38"  style="opacity:0.1">
                                                          </div>

                                                                <div class="pl-2" style="flex-basis:80%">
                                                                    <p><b>{{tran.policy}} </b></p>

                                                                    <p> {{defaultProductSelected.policy}} </p>

                                                                    <!-- <i class="fa fa-info-circle" :title="val" v-tippy='{ arrow : true, arrowType : "round"}'></i> -->

                                                                    <!-- <li>{{defaultProductSelected.policy.multiperil}} <i class="fa fa-info-circle" :title="tran.no_longer_edit" v-tippy='{ arrow : true, arrowType : "round"}'></i> </li>
                                                                 nb    <li>{{defaultProductSelected.policy.GoodsInTransit}}</li> -->

                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="requid-sign-up">
                                                            <p>ReQuid allows you to invest in verified agro investment products, with an option to liquidate(sell) when you wish</p>
                                                            <center>

                                                                <button class="mt-2" @click="gotoSignup">
                                                                        sign up
                                                                    </button>
                                                            </center>
                                                            <div style="position:relative">
                                                                <img src="../../assets/images/99070.svg" alt="" height="58" style="opacity:0.01; position: absolute; opacity: 0.1; right: 152px;top: 6px;">
                                                                <img src="../../assets/images/grow.svg" alt="" height="68" style="opacity:0.01; position: absolute; opacity: 0.1; right: 209px; top: 6px;">
                                                            </div>

                                                            </div>
                                                        </div>
            </transition>
        </div>

        <!-- <div style="position:absolute; left:20%; top:2%; opacity:0.09; z-index:2">
                                <img src="../../assets/images/badge.svg" alt="" height="528"  style="opacity:0.3">
                    </div>

                        <div style="position:absolute; left:40%; top:2%; opacity:0.09; z-index:2">
                            <img src="../../assets/images/99070.svg" alt="" height="788" style="opacity:0.3">
                            </div> -->
    </div>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import trans from "../../../translations";

export default {
    data() {

        return {

            atpVerifiedDiv: false,
            atpDiv: true,
            atpSelected: '',
            tran: '',
            atpSelectedFromSelect2: '',
            imgUrl: null,
            atpProducts: [

            ],

            atpObjectDetail:

            {

                name: ''
            },

            productObject: {},

            defaultProductSelected: {

                name: ''
            },

            atpObject: [

                {
                    name: 'select an Atp',
                    id: 0
                },
                {
                    name: 'Farmforte Agro and Allied Solutions Ltd',
                    id: 1,
                    imgUrl: require('../../assets/images/agrop.png'),
                    products: [{
                            name: 'Cashew Nuts',
                            id: 665,
                            insurer: 'Leadway Assurance Plc',
                            status: 'active',
                            policy:

                                `Farm Property and Produce, This policy covers your farm against loss or damage to your farm property (e.g. farm equipment) and agricultural produce that is caused by fire, lightening, flood and other covered perils.
`
                        },
                        {
                            name: 'Sesame Seeds',
                            id: 662,
                            insurer: 'Leadway Assurance Plc',
                            status: 'active',
                            policy:

                                `Goods In Transit
This policy covers loss or damage to agricultural produce being carried from one location to another or  whilst in transit by road, rail and inland motor ways, as well as, when being loaded or unloaded.
`
                        },
                        {
                            name: 'Sweet Potatoes',
                            id: 3,
                            insurer: 'Leadway Assurance Plc',
                            status: 'active',
                            policy:

                                `Multiperil Crop Insurance:
This policy insures your farm against the risk of physical loss or damages to crops which may arise from fire, lightning, flood, explosion and other covered perils. The policy pays for the production costs of the crops lost from planting to the crops maturity stage.
`
                        },
                    ]
                },

                {
                    name: 'Payfarmer',
                    id: 2,
                    products: [

                        {
                            name: 'Pigs',
                            id: 151,

                            insurer: 'Leadway Assurance Plc',
                            status: 'active',
                            policy:

                                `Insurance Policy covering Livestock:
This policy is also known as Livestock Insurance Policy. It covers the liability of livestock against risk of death. The standard coverage provided by the policy pays you the assessed value of the lost livestock as a result of diseases, fire, flood, lightning, windstorm and accident.
`
                        },

                    ]
                },

                {
                    name: 'Thrive Agric',
                    id: 3,
                    imgUrl: require('../../assets/images/agrop2.jpg'),
                    products: [

                        {
                            name: 'Poultry risk (Noilers)',
                            id: 1333,
                            insurer: 'Leadway Assurance Plc',
                            status: 'active',
                            policy:

                                `Poultry risk insurance policy:
This policy covers your liability as a poultry farmer or investor in the event of death of your birds which may be caused directly by fire, flood, windstorm, lightning, accident and disease. The policy secures the capital you have invested and pays the assessed value of your birds in the event of death.
`
                        },
                        {
                            name: 'Sorghum, Rice and Maize',
                            id: 113,
                            insurer: 'Leadway Assurance Plc',
                            status: 'active',
                            policy:

                                `Multiperil Crop Insurance:
This policy insures your farm against the risk of physical loss or damages to crops which may arise from fire, lightning, flood, explosion and other covered perils. The policy pays for the production costs of the crops lost from planting to the crops maturity stage
`
                        },

                    ]
                },
                {
                    name: 'Farmkart Foods Investment Limited',
                    id: 4,
                    imgUrl: require('../../assets/images/agrop2.jpg'),
                    products: [

                        {
                            name: 'Fishery',
                            id: 13,
                            insurer: 'Leadway Assurance Plc',
                            status: 'active',
                            policy:

                                `Fish Farm Insurance:
This policy insures your fishes against death and also insures the fish bond against any collapse as a result of any accident. In the event of the death of your fish stock or collapse of the fish pond, the standard coverage provided by this will pay you the assessed value of the fish lost and the amount required to restore the fishpond back to its position, respectively.
`
                        },

                    ]
                },
                {
                    name: 'Winich Farms ',
                    id: 500,
                    imgUrl: require('../../assets/images/agrop2.jpg'),
                    products: [

                        {
                            name: 'Fishery',
                            id: 90,
                            insurer: 'Leadway Assurance Plc',
                            status: 'active',
                            policy:

                                `Fish Farm Insurance:
This policy insures your fishes against death and also insures the fish bond against any collapse as a result of any accident. In the event of the death of your fish stock or collapse of the fish pond, the standard coverage provided by this will pay you the assessed value of the fish lost and the amount required to restore the fishpond back to its position, respectively
`
                        },

                    ]
                },
            ],

            atpName: ''
        }
    },
    watch: {

    },
    computed: {
        ...mapState({

            bankNames: state => state.auth.bankNames,

        }),
        atpProvider() {
            return this.atpSelectedFromSelect2
        },
        returnAtpProducts() {
            return this.atpProducts
        },

        productSelectedByUser() {

            return this.atpObjectDetail
        }
    },

    methods: {

        gotoSignup() {

            this.$router.push('/signup')
        },

        showAtpDiv() {
            this.atpVerifiedDiv = false
            this.atpDiv = true
        },

        getProductFromAtpProducts(event) {
            this.defaultProductSelected = false
            let vm = this
            this.returnAtpProducts.filter(function (data) {

                if (data.id == event.target.value) {
                    vm.atpObjectDetail.name = data.name
                    vm.atpObjectDetail.insurer = data.insurer
                    vm.atpObjectDetail.status = data.status
                    vm.atpObjectDetail.policy = data.policy
                }

            })

        },
        showAtpDetail(id) {

            if (id !== undefined) {
                this.atpSelected = id

            }

            if (this.atpSelected == 0) {

                return;
            }
            let vm = this
            this.atpDiv = false;
            setTimeout(() => {
                this.atpVerifiedDiv = true;

            }, 1000)

            this.atpSelectedFromSelect2 = this.atpObject.filter(function (data) {
                if (data.id == vm.atpSelected) {
                    vm.defaultProductSelected = data.products[0]
                    vm.atpProducts = data.products
                    return data;

                }
            })
            this.atpName = this.atpSelectedFromSelect2[0].name
            // this.imgUrl = this.atpSelectedFromSelect2[0].imgUrl

        },

    },
    mounted() {

        this.tran = trans;

        (function () {

            var DRIFT_CHAT_SELECTOR = '.drift-open-chat'
            /* http://youmightnotneedjquery.com/#ready */
            function ready(fn) {
                if (document.readyState != 'loading') {
                    fn();
                } else if (document.addEventListener) {
                    document.addEventListener('DOMContentLoaded', fn);
                } else {
                    document.attachEvent('onreadystatechange', function () {
                        if (document.readyState != 'loading')
                            fn();
                    });
                }
            }
            /* http://youmightnotneedjquery.com/#each */
            function forEachElement(selector, fn) {
                var elements = document.querySelectorAll(selector);
                for (var i = 0; i < elements.length; i++)
                    fn(elements[i], i);
            }

            function openSidebar(driftApi, event) {
                event.preventDefault();
                driftApi.sidebar.open();
                return false;
            }
            ready(function () {
                drift.on('ready', function (api) {
                    var handleClick = openSidebar.bind(this, api)
                    forEachElement(DRIFT_CHAT_SELECTOR, function (el) {
                        el.addEventListener('click', handleClick);
                    });
api.widget.hide();
                });
            });
        })();
    }
}
</script>

<style>
.spinner {
    margin: 100px auto 0;
    width: 100px;
    text-align: center;
}

ul {

    padding-left: 4px !important;
}

li {
    list-style-type: none;
}

.spinner>div {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    background-color: #BDBDBD;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
    animation: bouncedelay 1.4s infinite ease-in-out;
    /* Prevent first frame from flickering when animation starts */
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.48s;
    animation-delay: -0.48s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce3 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0.5);
        opacity: .5;
    }

    40% {
        -webkit-transform: scale(1.0);
        opacity: 1;
    }
}

@keyframes bouncedelay {

    0%,
    80%,
    100% {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
        opacity: .5;
    }

    40% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
        opacity: 1;
    }
}
</style>

<template>
<div>
    <AdTool />
</div>
</template>

<script>
import AdTool from '../components/partials/AdTool.vue'
export default {
    components: {
        AdTool
    }
}
</script>

